table:not(.exclude-styles) tr:nth-child(3n) {
  background-color: #ff00000c;
}

table:not(.exclude-styles) tr:nth-child(3n + 2) {
  background-color: #00ff4c14;
}

table:not(.exclude-styles) tr:nth-child(3n + 1) {
  background-color: #005eff10;
}


  /* Loader Css */

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    border: 12px solid #5be978;
    border-top: 12px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  