.feedback-form-container {
    /* max-width: 400px; */
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    /* text-align: center; */
  }
  
  .feedback-form h2 {
    margin-bottom: 20px;
  }
  
  .emoji-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .emoji-rating >p{
   min-width: 370px;
  }
  
  .emoji-button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .emoji-button.selected {
    transform: scale(1.2);
  }
  
  .feedback-textarea {
    width: 100%;
    height: 80px;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    resize: none;
  }
  
  .submit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .thank-you-message {
    text-align: center;
    font-size: 1.2rem;
  }
  