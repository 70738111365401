.registration-main{
    background-image: url("https://aashvipt.com/wp-content/uploads/elementor/thumbs/hm3-q6lc0dggxnszhadnyot24845fsxag5kfmfgjd3tyqo.jpg");
    background-repeat: no-repeat;
    
}


  .button-PT-registration {
    margin-top: 1.75rem;
    position: relative;
    width: 150px;
    height: 55px;
    border: 0;
    outline: none;
    background: #2b2d42a5;
    color: #fff;
    font-weight: 700;
    font-size: 22px;
    border-radius: 40px;
    text-align: center;
    box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    cursor: pointer;
  }
  .checked {
    width: 75px;
    height: 75px;
    border-radius: 40px;
    box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.2);
    position: absolute;
    top:0;
    right: -40px;
    opacity: 0;
    background: #d90429;
  }
  